<template>
  <v-container>
    <v-row v-if="!checkMobile">
      <v-col cols="6">
        <v-card>
          <v-card-title>My Profile</v-card-title>
          <v-card-text>
            <v-form v-on:submit.prevent>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="data.firstname"
                    label="First Name"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="data.lastname"
                    label="Last Name"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="data.address1"
                    label="Address"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="data.address2"
                    label="Address Cont."
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field v-model="data.city" label="City"></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    v-model="data.state"
                    label="State"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field v-model="data.zip" label="ZIP"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="data.email"
                    label="eMail"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="data.phone"
                    label="Primary Phone"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn v-if="false" color="primary">Update</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card>
          <v-card-title class="d-flex align-center justify-space-between">
            <h4>Recent login activities</h4>
          </v-card-title>
          <v-card-text>
            <v-data-table
              :loading="loading"
              :headers="headersSessions"
              :items="sessions"
              :items-per-page="5"
              class="elevation-0"
            >
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip top color="error">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <v-icon
                        small
                        @click="logoutSpecific(item.id)"
                        color="error"
                      >
                        mdi-logout
                      </v-icon>
                    </span>
                  </template>
                  <span>Logout</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-title class="d-flex align-center justify-space-between">
            <v-btn
              class="d-flex"
              elevation="0"
              color="error"
              @click="logoutAll"
            >
              Logout All
            </v-btn>
            <v-btn
              class="d-flex ml-2"
              elevation="0"
              color="error"
              @click="logoutAllNotCurrent"
            >
              Logout All Not Current
            </v-btn>
          </v-card-title>
        </v-card>
      </v-col>
      <v-col>
        <v-btn
          class="d-flex ml-2"
          @click="openModal()"
          elevation="0"
          style="width: 100%"
        >
          Update Insurance
        </v-btn>
        <UpdateInsuranceDialog
          ref="updateInsurance"
          :dialog="dialog"
          :submitting="submitting"
          @close="dialog = !dialog"
        />
      </v-col>
      <v-col cols="6" v-if="isShowPasswordReset">
        <PasswordReset @reset="reloadPasswordReset" />
      </v-col>
    </v-row>
    <v-row v-else>
      <v-card style="margin-bottom: 10px">
        <v-card-title>My Profile</v-card-title>
        <v-card-text>
          <v-form v-on:submit.prevent>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="data.firstname"
                  label="First Name"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="data.lastname"
                  label="Last Name"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="data.address1"
                  label="Address"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="data.address2"
                  label="Address Cont."
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="8">
                <v-text-field v-model="data.city" label="City"></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field v-model="data.state" label="State"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="data.zip" label="ZIP"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="data.email" label="eMail"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="data.phone"
                  label="Primary Phone"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn v-if="false" color="primary">Update</v-btn>
        </v-card-actions>
      </v-card>

      <v-card style="margin-bottom: 10px">
        <v-card-title class="d-flex align-center justify-space-between">
          <h4>Recent login activities</h4>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :loading="loading"
            :headers="headersSessions"
            :items="sessions"
            :items-per-page="5"
            class="elevation-0"
            style="font-size: 8px"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip top color="error">
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <v-icon
                      small
                      @click="logoutSpecific(item.id)"
                      color="error"
                    >
                      mdi-logout
                    </v-icon>
                  </span>
                </template>
                <span>Logout</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-title class="d-flex align-center justify-space-between">
          <v-row>
            <v-col cols="12">
              <v-btn
                class="d-flex"
                elevation="0"
                color="error"
                @click="logoutAll"
                style="width: 100%; margin-left: 5px"
              >
                Logout All
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-btn
                class="d-flex ml-2"
                elevation="0"
                color="error"
                @click="logoutAllNotCurrent"
                style="width: 100%"
              >
                Logout All Not Current
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
      </v-card>
      <v-row>
        <v-btn
          class="d-flex ml-2"
          @click="openModal()"
          elevation="0"
          style="width: 100%"
        >
          Update Insurance
        </v-btn>
        <UpdateInsuranceDialog
          ref="updateInsurance"
          :dialog="dialog"
          :submitting="submitting"
          @close="dialog = !dialog"
        />
      </v-row>
      <v-row v-if="isShowPasswordReset">
        <PasswordReset @reset="reloadPasswordReset" />
      </v-row>
    </v-row>
  </v-container>
</template>
<script>
import { auth, api } from '../sharedPlugin';
import axios from 'axios';
import PasswordReset from '../components/passreset/passwordreset.vue';
import UpdateInsuranceDialog from '../components/UpdateInsurance.vue';
import device from '../utils/device';

export default {
  components: {
    PasswordReset,
    UpdateInsuranceDialog
  },
  data() {
    return {
      loading: false,
      isShowPasswordReset: true,
      dialog: false,
      data: [],
      pass1: null,
      pass2: null,
      isFormValid: false,
      message: '',
      showSnackBar: false,
      color: '',
      show1: true,
      rules: {
        required: (value) => !!value || 'Required.',
        min: (v) => v?.length >= 8 || 'Min 8 characters',
        match: this.pass1 === this.pass2 || 'Passwords do not match'
      },
      headersSessions: [
        {
          text: 'Device',
          align: 'start',
          sortable: false,
          value: 'device'
        },
        { text: 'Logged in', sortable: false, value: 'logged_in_at' },
        { text: 'Actions', sortable: false, align: 'end', value: 'actions' }
      ],
      sessions: []
    };
  },
  computed: {
    ...auth.mapComputed(['user']),
    checkMobile() {
      return device() === 'mobile' ? true : false;
    }
  },
  async mounted() {
    await this.getPatientInfo();
    await this.getSessions();
  },
  methods: {
    ...auth.mapMethods(['logout']),
    reloadPasswordReset() {
      this.isShowPasswordReset = false;
      setTimeout(() => {
        this.isShowPasswordReset = true;
      }, 0);
    },
    showMessage({ message, color }) {
      this.color = color;
      this.message = message;
      this.showSnackBar = true;
    },
    async logoutSpecific(id) {
      this.loading = true;
      await api.UserSessions.logout(id)
        .then(() => {
          this.getSessions();
        })
        .finally(() => (this.loading = false));
    },
    async logoutAll() {
      this.loading = true;
      await api.UserSessions.logoutAll()
        .then(() => {
          this.getSessions();
        })
        .finally(() => (this.loading = false));
    },
    async logoutAllNotCurrent() {
      this.loading = true;
      await api.UserSessions.logoutAllNotCurrent()
        .then(async () => {
          await this.getSessions();
        })
        .finally(() => (this.loading = false));
    },
    async getSessions() {
      this.loading = true;
      const { token } = JSON.parse(localStorage.getItem('auth'));
      await api.UserSessions.getSessions()
        .then(({ data }) => {
          const sessions = data.map((session) => {
            session.device = session?.device?.match(/^[^(]+\([^)]*\)/);
            session.logged_in_at = new Date(
              session?.logged_in_at
            ).toLocaleString('en-US', {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric'
            });
            session.current = session.token === token;
            return session;
          });
          this.sessions = sessions;
        })
        .catch(() => {
          this.sessions = [];
          this.showMessage({
            message: 'There was an error getting the sessions',
            color: 'error'
          });
        })
        .finally(() => (this.loading = false));
    },
    async resetPass() {
      await axios
        .put(
          `${process.env.VUE_APP_API_URL}/api/v1/connect/auth/reset`,
          {
            id: this.user.userId,
            password: this.pass1
          },
          {
            headers: {
              'x-api-key':
                '58b54801044df5a0536c25db11c653c743761b2db21e044aedb9f91caa6138a5'
            }
          }
        )
        .then((response) => {
          this.$store.commit('showSnackbar', {
            message: 'Your password has been updated',
            color: 'green'
          });
          this.pass1 = null;
        })
        .catch((err) => {
          console.error(err);
          this.$store.commit('showSnackbar', {
            message:
              'There was an error updating your password. Please try again.',
            color: 'red'
          });
        });
    },
    async updatePatient() {},

    openModal() {
      console.log('Open Modal');
      this.$refs.updateInsurance.show();
    },

    async getPatientInfo() {
      return await axios
        .post(
          `${process.env.VUE_APP_API_URL}/api/v1/inova/patients/getConnectPatient`,
          {
            connectId: this.user.userId
          },
          {
            headers: {
              'x-api-key':
                '58b54801044df5a0536c25db11c653c743761b2db21e044aedb9f91caa6138a5'
            }
          }
        )
        .then((response) => {
          this.data = response.data[0];

          this.showMessage({
            message: 'Your profile was updated.',
            color: 'green'
          });
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }
};
</script>
